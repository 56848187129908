<template>
	<div style="height: 100%;">
		<main-table tool>
			<div slot="table" class="df f1 fdc">
				<div class="df fww">
					<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
						<div class="df jcsb mb5">
							<div class="fs16 fw6 c3">{{status.count || 0}}</div>
						</div>
						<div class="fs14 c6">{{status.name}}</div>
					</div>
					<div class="mb10">
						<el-button type="primary" size="mini" @click="show_fillter = !show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
					</div>
				</div>
				<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
					<el-form-item >
						<el-input v-model="params.keyword" placeholder="请输入流水" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.oa_type" placeholder="OA" filterable clearable>
							<el-option v-for="(type, t) in oa_types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.model_type" placeholder="内容" filterable clearable>
							<el-option v-for="(type, t) in model_types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getApprovals({ ...params, page: 1})">查询</el-button>
					</el-form-item>
				</el-form>
				<div class="f1 pr">
					<el-table class="scroll-wrapper pa" height="100%" :data="approvals" :size="theme.size">
						<el-table-column label="编码" prop="sp_no" min-width="120"></el-table-column>
						<el-table-column label="OA" min-width="80" :formatter="({approval_template}) => { return oa_types[approval_template.oa_type] || '自建审核'; }"></el-table-column>
						<el-table-column label="模板" prop="approval_template.template_id" min-width="320"></el-table-column>
						<el-table-column label="类型" min-width="100" :formatter="({common_model_type}) => { return model_types[common_model_type] || '未知'; }"></el-table-column>
						<el-table-column label="流水" prop="common_model.no" min-width="180"></el-table-column>
						<el-table-column label="状态" prop="status" min-width="80" :formatter="(r) => { return statuses[r.status]; }"></el-table-column>
						<el-table-column label="更新时间" prop="updated_at" min-width="160" v-if="theme.width >= 1080"></el-table-column>
						<el-table-column label="创建时间" prop="created_at" min-width="160" v-if="theme.width >= 1080"></el-table-column>
					</el-table>
				</div>
			</div>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getApprovals({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getApprovals({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
	</div>
</template>


<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getApprovals(this.params);
			},
			async getApprovals (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_APPROVALS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.approvals = result.data;
				this.statuses = result.statuses;
				this.oa_types = result.oa_types;
				this.model_types = result.model_types;
				if (result.counts) {
					var status_tabs = {};
					const status = params.status || [];
					for (var s in this.statuses) {
						status_tabs[s] = {
							name: this.statuses[s],
							count: result.counts[s] || 0,
							checked: status.indexOf(s) >= 0 ? true : false
						};
					}
					this.status_tabs = status_tabs;
				}
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				oa_types: [],
				statuses: [],
				approvals: [],
				model_types: [],
				status_tabs: [],
				show_fillter: false,
				params: {
					status: [],
					perPage: 10,
				}
			}
		},
		async created () {
			this.getApprovals(this.params, true);
		}
	};
</script>